import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { intervalToDuration } from 'date-fns'
import { graphql } from 'gatsby'
import rainbowRectangle from '@assets/images/rainbow-rectangle.png'
import useIsFirstRender from '@hooks/useIsFirstRender'

const OuterWrapper = styled('div')(({ theme, DesktopSpacing, MobileSpacing }) => ({
  paddingTop: MobileSpacing?.TopPadding ?? 0,
  paddingBottom: MobileSpacing?.BottomPadding ?? 0,
  [theme.breakpoints.up('md')]: {
    paddingTop: DesktopSpacing?.TopPadding ?? 0,
    paddingBottom: DesktopSpacing?.BottomPadding ?? 0,
  },
}))

const RainbowWrapper = styled('div')(({ theme }) => ({
  background: `url(${rainbowRectangle})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: '2px 2.75px',
  width: 'fit-content',
  borderRadius: '16px',
  filter: 'drop-shadow(0px 3.4817047119140625px 3.4817047119140625px rgba(0, 0, 0, 0.25))',
  [theme.breakpoints.up('md')]: {
    borderRadius: '25.6px',
    padding: '3.5px 4.5px',
  },
}))

const CountdownWrapper = styled('div')(
  ({ theme, backgroundColor, secondaryBackgroundColor, borderColor, isShadow, textColor }) => ({
    display: 'flex',
    background: `linear-gradient(135deg, ${backgroundColor} 45%, ${secondaryBackgroundColor} 45%)`,
    color: textColor,
    padding: '3px 0px',
    width: 'fit-content',
    borderRadius: '14px',
    border: `3px solid ${borderColor}`,
    filter: isShadow ? 'drop-shadow(0px 3.4817047119140625px 3.4817047119140625px rgba(0, 0, 0, 0.25))' : null,
    [theme.breakpoints.up('md')]: {
      padding: '5px 0px',
      borderRadius: '22.4px',
      border: `4.5px solid ${borderColor}`,
    },
  }),
)

const TimeSegment = styled('div')(({ theme }) => ({
  width: '51px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '81px',
  },
}))

const TimeText = styled('div')(({ theme }) => ({
  fontSize: '43.5px',
  fontFamily: 'League Gothic, sans-serif !important',
  lineHeight: '88%',
  paddingBottom: '2px',
  [theme.breakpoints.up('md')]: {
    fontSize: '69.6px',
    paddingBottom: '2px',
  },
}))

const TimeUnit = styled('div')(({ theme }) => ({
  fontSize: '7.4px',
  fontFamily: 'Montserrat, sans-serif !important',
  lineHeight: 'normal',
  [theme.breakpoints.up('md')]: {
    fontSize: '11.8px',
  },
}))

const determineDuration = date =>
  intervalToDuration({
    start: new Date(date),
    end: new Date(),
  })

const formatTwoDigits = num => num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

const DEFAULT_BLACK = '#080606'
const DEFAULT_WHITE = '#FFFFFF'

const StrapiCountdown = ({ data }) => {
  const {
    ExpirationDate,
    BackgroundColor,
    SecondaryBackgroundColor,
    BorderColor,
    RainbowOuterBorder,
    TextColor,
    DesktopSpacing,
    MobileSpacing,
  } = data || {}

  const [duration, setDuration] = useState(determineDuration(ExpirationDate))
  const [isExpired, setIsExpired] = useState(new Date(ExpirationDate) < new Date())
  const isFirstRender = useIsFirstRender()

  const backgroundColor = useMemo(() => BackgroundColor?.ColorHex ?? DEFAULT_BLACK, [BackgroundColor])
  const secondaryBackgroundColor = useMemo(() => {
    let color = BackgroundColor?.ColorHex ?? DEFAULT_BLACK
    if (SecondaryBackgroundColor?.ColorHex) color = SecondaryBackgroundColor?.ColorHex
    return color
  }, [BackgroundColor, SecondaryBackgroundColor])
  const borderColor = useMemo(() => BorderColor?.ColorHex ?? DEFAULT_BLACK, [BorderColor])
  const textColor = useMemo(() => TextColor?.ColorHex ?? DEFAULT_WHITE, [TextColor])

  useEffect(() => {
    const interval = setInterval(() => {
      const newDuration = determineDuration(ExpirationDate)
      setDuration(newDuration)
      if (new Date(ExpirationDate) < new Date()) {
        setIsExpired(true)
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [ExpirationDate])

  const renderTimeText = interval => {
    if (isFirstRender) return <TimeText>&nbsp;</TimeText>
    return <TimeText>{formatTwoDigits(duration?.[interval])}</TimeText>
  }

  const renderCountdown = isShadow => (
    <CountdownWrapper
      backgroundColor={backgroundColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      borderColor={borderColor}
      isShadow={isShadow}
      textColor={textColor}
    >
      <TimeSegment>
        {renderTimeText('days')}
        <TimeUnit>DAYS</TimeUnit>
      </TimeSegment>
      <TimeText>:</TimeText>
      <TimeSegment>
        {renderTimeText('hours')}
        <TimeUnit>HOURS</TimeUnit>
      </TimeSegment>
      <TimeText>:</TimeText>
      <TimeSegment>
        {renderTimeText('minutes')}
        <TimeUnit>MINUTES</TimeUnit>
      </TimeSegment>
      <TimeText>:</TimeText>
      <TimeSegment>
        {renderTimeText('seconds')}
        <TimeUnit>SECONDS</TimeUnit>
      </TimeSegment>
    </CountdownWrapper>
  )

  if (isExpired) return <></>

  return (
    <OuterWrapper DesktopSpacing={DesktopSpacing} MobileSpacing={MobileSpacing}>
      {RainbowOuterBorder && <RainbowWrapper>{renderCountdown()}</RainbowWrapper>}
      {!RainbowOuterBorder && renderCountdown(true)}
    </OuterWrapper>
  )
}

export default StrapiCountdown

StrapiCountdown.propTypes = {
  data: PropTypes.object.isRequired,
}

export const countdownFragment = graphql`
  fragment StrapiCoundownFragment on StrapiCountdown {
    Title
    ExpirationDate
    BackgroundColor {
      ColorHex
    }
    SecondaryBackgroundColor {
      ColorHex
    }
    BorderColor {
      ColorHex
    }
    TextColor {
      ColorHex
    }
    RainbowOuterBorder
    DesktopSpacing {
      BottomPadding
      TopPadding
    }
    MobileSpacing {
      BottomPadding
      TopPadding
    }
  }
`
